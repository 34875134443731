import featured from "./assets/morarumariuca.jpg";
import logo from "./assets/logo.png";
import "./App.css";

import MailchimpSubscribe from "react-mailchimp-subscribe";

function Home() {
  const now = new Date();
  const year = now.getFullYear();

  const url =
    "https://mariucamoraru.us21.list-manage.com/subscribe/post?u=54c839ddc4b949d0bc9e16b5b&amp;id=72593b07d6&amp;f_id=00a262e1f0";

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <div className="flex flex-col my-12">
        {status === "sending" && (
          <div className="text-white mb-3 text-lg">se trimite...</div>
        )}
        {status === "error" && (
          <div
            className="mb-3 text-red-400 text-lg"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mb-3 text-green-400 text-lg"
            dangerouslySetInnerHTML={{
              __html: message.includes("Thank you for subscribing!")
                ? "Mulțumesc pentru abonare! Mă bucur să te am alături!"
                : message,
            }}
          />
        )}
        <div className="flex flex-col md:flex-row">
          <input
            className="text-2xl px-6 h-16 flex-grow text-black"
            ref={(node) => (email = node)}
            type="email"
            placeholder="Adresa ta de email"
          />
          <button
            className="text-2xl px-6 h-16 text-white hover:text-red-100"
            style={{ background: "#e1424c" }}
            onClick={submit}
          >
            Abonare
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-between items-center min-h-screen py-16">
      <div>
        <div className="flex flex-col gap-6 items-center pb-6">
          <div className="flex-grow">
            <img src={logo} width={195} height={32} alt="Logo" />
          </div>
          <div>
            <p className="text-white">Jurnal de bord - PODCAST</p>
          </div>
        </div>
      </div>
      <div className="px-4">
        <div className="flex flex-col gap-10">
          <div className="relative">
            <img
              src={featured}
              width={1280}
              height={720}
              alt="Moraru Mariuca"
              className="lg:w-3/4 mx-auto"
            />

            <div className="lg:absolute lg:w-2/4 right-0 bottom-16 bg-black text-white p-6 text-lg opacity-90">
              Acest podcast este un spațiu în care voi împărtăși deschis și
              sincer experiențele și lecțiile pe care le-am învățat pe parcursul
              călătoriei mele de mamă. Voi explora cum am reușit să găsesc
              echilibrul între responsabilitățile de părinte și nevoile mele
              personale de a crește și de a evolua.
            </div>
          </div>

          <div className="text-white opacity-50 text-center">Mă găsiți pe:</div>
          <div className="text-center">
            <div className="flex gap-6 flex-col text-white text-3xl">
              <a
                href="https://www.youtube.com/@jurnaldebord"
                target="_blank"
                rel="noopener nofollow"
              >
                YouTube
              </a>
              <a
                href="https://www.patreon.com/MariucaMoraru"
                target="_blank"
                rel="noopener nofollow"
              >
                Patreon
              </a>
              <a
                href="https://open.spotify.com/show/6JO4jksUJuHO7fIX313sAI"
                target="_blank"
                rel="noopener nofollow"
              >
                Spotify
              </a>
              <a
                href="https://music.amazon.co.uk/podcasts/c936826f-bde0-4326-9ce0-ba406731caf5/jurnal-de-bord"
                target="_blank"
                rel="noopener nofollow"
              >
                Amazon Music
              </a>
              <a
                href="https://podcasts.apple.com/us/podcast/jurnal-de-bord/id1697098587"
                target="_blank"
                rel="noopener nofollow"
              >
                Apple Podcasts
              </a>
              <a
                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9lNWExMzIyOC9wb2RjYXN0L3Jzcw"
                target="_blank"
                rel="noopener nofollow"
              >
                Google Podcasts
              </a>
              <a
                href="https://castbox.fm/channel/id5512602"
                target="_blank"
                rel="noopener nofollow"
              >
                Castbox
              </a>
              <a
                href="https://radiopublic.com/jurnal-de-bord-WxgPdo"
                target="_blank"
                rel="noopener nofollow"
              >
                RadioPublic
              </a>
              <a
                href="https://www.stitcher.com/show/1075486"
                target="_blank"
                rel="noopener nofollow"
              >
                Stitcher
              </a>
              <a
                href="https://www.twitter.com/morarumariuca"
                target="_blank"
                rel="noopener nofollow"
              >
                Twitter
              </a>
              <a
                href="https://www.facebook.com/mariucamoraru"
                target="_blank"
                rel="noopener nofollow"
              >
                Facebook
              </a>
              <a
                href="https://www.facebook.com/groups/jurnaldebord/"
                target="_blank"
                rel="noopener nofollow"
              >
                Facebook Group
              </a>

              <a
                href="https://www.instagram.com/morarumariuca"
                target="_blank"
                rel="noopener nofollow"
              >
                Instagram
              </a>
              <a
                href="https://www.tiktok.com/@mariucamoraru"
                target="_blank"
                rel="noopener nofollow"
              >
                TikTok
              </a>
            </div>
          </div>
        </div>
        <div className="text-red-500 w-full lg:w-3/4 mx-auto">
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <div>
                <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                />
              </div>
            )}
          />
        </div>
      </div>

      <div className="flex gap-6 pt-6">
        <p className="text-white opacity-50">Copyright {year} Mariuca Moraru</p>
      </div>
    </div>
  );
}

export default Home;
