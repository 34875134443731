import logo from "./assets/logo.png";
import "./App.css";

function Privacy() {
  return (
    <div className="flex flex-col justify-start gap-6 items-center min-h-screen py-16">
      <div className="flex flex-col gap-3 items-center mb-7">
        <a href="/">
          <img src={logo} width={100} height={100} alt="one tiny app" />
        </a>
      </div>
      <div className="text-white flex flex-col gap-4 max-w-4xl mx-auto mb-7 px-4">
        <h1 className="text-2xl lg:text-4xl">Privacy Policy</h1>
        <p>
          <strong>Last updated: June 30, 2023</strong>
        </p>
        <p>
          This Privacy Policy describes how One Tiny App (the "Site", "we",
          "us", or "our") collects, uses, and discloses your personal
          information when you visit, use our services, or make a purchase from
          onetinyapp.com (the "Site") or otherwise communicate with us
          (collectively, the "Services"). For purposes of this Privacy Policy,
          "you" and "your" means you as the user of the Services, whether you
          are a customer, website visitor, or another individual whose
          information we have collected pursuant to this Privacy Policy.
        </p>
        <p>
          Please read this Privacy Policy carefully. By using and accessing any
          of the Services, you agree to the collection, use, and disclosure of
          your information as described in this Privacy Policy. If you do not
          agree to this Privacy Policy, please do not use or access any of the
          Services.
        </p>
        <h3 className="text-2xl">Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time, including to
          reflect changes to our practices or for other operational, legal, or
          regulatory reasons. We will post the revised Privacy Policy on the
          Site, update the "Last updated" date and take any other steps required
          by applicable law.
        </p>

        <h3 className="text-2xl">What Personal Information We Collect</h3>
        <p>
          The types of personal information we obtain about you depends on how
          you interact with our Site and use our Services. When we use the term
          "personal information", we are referring to information that
          identifies, relates to, describes or can be associated with you. The
          following sections describe the categories and specific types of
          personal information we collect.
        </p>
        <h3 className="text-2xl">Information We Collect Directly from You</h3>
        <p>
          We collect personal information directly from the relevant individual,
          through your Shopify account, or using the following technologies:
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org. “Log files” track actions occurring on
          the Site, and collect data including your IP address, browser type,
          Internet service provider, referring/exit pages, and date/time stamps.
          “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
        </p>
        <h3 className="text-2xl">‍How Do We Use Your Personal Information?</h3>
        <p>
          ‍We use the personal information we collect from you and your
          customers in order to provide the Service and to operate the App.
          Additionally, we use this personal information to: communicate with
          you; optimize or improve the App; and provide you with information or
          advertising relating to our products or services.
        </p>
        <h3 className="text-2xl">‍Sharing Your Personal Information</h3>
        <p>
          ‍We do not rent or sell your personally identifiable information (such
          as name, business name and email) to third parties for their marketing
          purposes. We may share your information with third parties to provide
          products and services you have requested, when we have your consent,
          or as described in this Privacy Policy. We may also share your
          Personal Information to comply with applicable laws and regulations,
          to respond to a subpoena, search warrant or other lawful request for
          information we receive, or to otherwise protect our rights.
        </p>
        <h3 className="text-2xl">Security and Retention of Your Information</h3>
        <p>
          Please be aware that no security measures are perfect or impenetrable,
          and we cannot guarantee "perfect security." In addition, any
          information you send to us may not be secure while in transit. We
          recommend that you do not use unsecure channels to communicate
          sensitive or confidential information to us.
        </p>
        <p>
          How long we retain your personal information depends on different
          factors, such as whether we need the information to maintain your
          account, to provide the Services, comply with legal obligations,
          resolve disputes or enforce other applicable contracts and policies.
        </p>
        <h3 className="text-2xl">Contact</h3>
        <p>
          Should you have any questions about our privacy practices or this
          Privacy Policy, or if you would like to exercise any of the rights
          available to you, please email us at morarucatalinvasile@gmail.com.
        </p>
      </div>
      <div className="flex gap-6">
        <a href="/" className="text-white underline">
          Back
        </a>{" "}
        <a
          href="mailto:morarucatalinvasile@gmail.com"
          className="text-white underline"
        >
          Contact
        </a>
      </div>
    </div>
  );
}

export default Privacy;
