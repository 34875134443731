import logo from "./assets/logo.png";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
